import { createApp } from 'vue';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import App from './App.vue';
import '@arco-design/web-vue/dist/arco.css';
import router from './router/index.js';
import { createPinia } from 'pinia';
import { Modal } from '@arco-design/web-vue';
import '@/assets/base.css';



const pinia = createPinia()
const app = createApp(App);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(pinia);
app.use(router);
Modal._context = app._context;
app.mount('#app');